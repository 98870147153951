<template>
  <div class="login">
    <el-form
      ref="loginForm"
      :model="loginForm"
      :rules="loginRules"
      class="login-form"
    >
      <h3 class="title">万图信息五悦云管理系统</h3>
      <el-form-item prop="username">
        <el-input
          v-model="loginForm.username"
          type="text"
          auto-complete="off"
          placeholder="请输入账号"
        >
          <!-- <svg-icon
              slot="prefix"
              icon-class="user"
              class="el-input__icon input-icon"
            /> -->
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          v-model="loginForm.password"
          type="password"
          auto-complete="off"
          placeholder="请输入密码"
          @keyup.enter.native="handleLogin"
        >
          <!-- <svg-icon
              slot="prefix"
              icon-class="password"
              class="el-input__icon input-icon"
            /> -->
        </el-input>
      </el-form-item>
      <el-form-item prop="code" v-if="captchaOnOff">
        <el-input
          v-model="loginForm.code"
          auto-complete="off"
          placeholder="验证码"
          style="width: 63%"
          @keyup.enter.native="handleLogin"
        >
          <!-- <svg-icon
              slot="prefix"
              icon-class="validCode"
              class="el-input__icon input-icon"
            /> -->
          <i class="el-input__icon input-icon"></i>
        </el-input>
        <div class="login-code">
          <img :src="codeUrl" @click="getCode" class="login-code-img" />
        </div>
      </el-form-item>
      <!-- <el-checkbox
          v-model="loginForm.rememberMe"
          style="margin: 0px 0px 25px 0px"
          >记住密码</el-checkbox
        > -->
      <el-form-item style="width: 100%">
        <el-button
          :loading="loading"
          size="medium"
          type="primary"
          style="width: 100%"
          @click.native.prevent="handleLogin"
        >
          <span v-if="!loading">登 录</span>
          <span v-else>登 录 中...</span>
        </el-button>
        <!-- <div style="float: right; color: #fff" v-if="register">
            <router-link class="link-type" :to="'/register'" style="color: #fff"
              >立即注册</router-link
            >
          </div> -->
      </el-form-item>
    </el-form>
    <!--  底部  -->
    <!-- <div class="el-login-footer">
        <span>Copyright © 2018-2022 ruoyi.vip All Rights Reserved.</span>
      </div> -->
  </div>
</template>

<script>
import { login, getInfo } from "@/api";
//   import Cookies from "js-cookie";
//   import { encrypt, decrypt } from "@/utils/jsencrypt";

export default {
  name: "Login",
  data() {
    return {
      codeUrl: "",
      loginForm: {
        username: "",
        password: "",
        rememberMe: false,
        code: "",
        uuid: "",
      },
      loginRules: {
        username: [
          {
            required: true,
            trigger: "blur",
            message: "请输入您的账号",
          },
        ],
        password: [
          {
            required: true,
            trigger: "blur",
            message: "请输入您的密码",
          },
        ],
        code: [
          {
            required: true,
            trigger: "change",
            message: "请输入验证码",
          },
        ],
      },
      loading: false,
      // 验证码开关
      captchaOnOff: false,
      // 注册开关
      register: true,
      redirect: undefined,
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  created() {
    //   this.getCode();
    //   this.getCookie();
  },
  methods: {
    getCode() {
      getCodeImg().then((res) => {
        this.captchaOnOff =
          res.captchaOnOff === undefined ? true : res.captchaOnOff;
        if (this.captchaOnOff) {
          this.codeUrl = "data:image/gif;base64," + res.img;
          this.loginForm.uuid = res.uuid;
        }
      });
    },
    getCookie() {
      const username = Cookies.get("username");
      const password = Cookies.get("password");
      const rememberMe = Cookies.get("rememberMe");
      this.loginForm = {
        username: username === undefined ? this.loginForm.username : username,
        password:
          password === undefined ? this.loginForm.password : decrypt(password),
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe),
      };
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          // this.loading = true;
          if (this.loginForm.rememberMe) {
            //   Cookies.set("username", this.loginForm.username, { expires: 30 });
            //   Cookies.set("password", encrypt(this.loginForm.password), {
            //     expires: 30,
            //   });
            //   Cookies.set("rememberMe", this.loginForm.rememberMe, {
            //     expires: 30,
            //   });
          } else {
            //   Cookies.remove("username");
            //   Cookies.remove("password");
            //   Cookies.remove("rememberMe");
          }
          login(JSON.stringify(this.loginForm)).then((res) => {
            // console.log(res);
            if (res.code == 200) {
              this.Storage.set("token", res.token);
              getInfo().then((r) => {
                // console.log(r);
                if (r.code == 200) {
                  this.Storage.set("userInfo", r.user);
                  this.$router.push("/");
                } else {
                  this.$message.error(r.msg);
                  this.Storage.clear();
                }
              });
            } else {
              this.$message.error(res.msg);
              this.Storage.clear();
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped rel="stylesheet/scss" lang="scss">
.login {
  position: relative;
  backdrop-filter: blur(30px);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url("../assets/login-background.jpg") fixed no-repeat;

  // background-image: url("../assets/images/login-background.jpg");
  background-size: cover;
}

.title {
  font-weight: bolder;
  margin: 0px auto 30px auto;
  text-align: center;
  color: #333;
}

.login-form:before {
  content: "";
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  display: block;
  position: absolute;
  left: -10px;
  top: -10px;
  background: inherit;
  box-shadow: inset 0 0 200px rgba(213, 213, 213, 0.8);
  filter: blur(10px);
  overflow: hidden;
  border-radius: 14px;
  z-index: -1;
}

.login-form {
  overflow: hidden;
  border: 1rpx solid #ccc;
  position: relative;
  opacity: 0.95;
  border-radius: 6px;
  background: inherit;
  width: 400px;
  padding: 25px 25px 5px 25px;

  .el-input {
    height: 38px;

    input {
      height: 38px;
    }
  }

  .input-icon {
    height: 39px;
    width: 14px;
    margin-left: 2px;
  }
}

.login-tip {
  font-size: 13px;
  text-align: center;
  color: #bfbfbf;
}

.login-code {
  width: 33%;
  height: 38px;
  float: right;

  img {
    cursor: pointer;
    vertical-align: middle;
  }
}

.el-login-footer {
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  font-family: Arial;
  font-size: 12px;
  letter-spacing: 1px;
}

.login-code-img {
  height: 38px;
}
</style>
